
<template>
  <div data-app>
      <div class="row">
          <UserInfo :begin="begin"   :currentVideo="''"></UserInfo>
      </div>
      <div>
          <v-row>
              <v-col lg="2"></v-col>
              <v-col lg="8" md="12" class="text-center container">
                  <div class="expression-title mt-4 mb-2">
                      {{parentTitle}} | {{lesson.title}}
                  </div>
                  <div class="timer-content">
                    <div class="bourdonTimer">
                      <v-icon>mdi-clock-outline</v-icon>
                      <div class="digital-watch">
                          <svg width="0" height="0" viewBox="0 0 0 0">
                              <defs>
                                  <g id="unit-h">
                                      <path d="M0 20 L20 40 L180 40 L200 20 L180 0 L20 0 Z"></path>
                                  </g>
                                  <g id="unit-v">
                                      <path d="M20 0 L0 20 L0 180 L20 200 L40 180 L40 20 Z"></path>
                                  </g>
                              </defs>
                          </svg>
                      
                          <div class="minute">
                              <svg id="minute-1" class="num-0" width="15.6" height="28.8" viewBox="0 0 260 480">
                                  <use xlink:href="#unit-h" class="segment a" x="30" y="0"></use>
                                  <use xlink:href="#unit-v" class="segment b" x="220" y="30"></use>
                                  <use xlink:href="#unit-v" class="segment c" x="220" y="250"></use>
                                  <use xlink:href="#unit-h" class="segment d" x="30" y="440"></use>
                                  <use xlink:href="#unit-v" class="segment e" x="0" y="250"></use>
                                  <use xlink:href="#unit-v" class="segment f" x="0" y="30"></use>
                                  <use xlink:href="#unit-h" class="segment g" x="30" y="220"></use>
                              </svg>
                              <svg id="minute-2" class="num-0" width="15.6" height="28.8" viewBox="0 0 260 480">
                                  <use xlink:href="#unit-h" class="segment a" x="30" y="0"></use>
                                  <use xlink:href="#unit-v" class="segment b" x="220" y="30"></use>
                                  <use xlink:href="#unit-v" class="segment c" x="220" y="250"></use>
                                  <use xlink:href="#unit-h" class="segment d" x="30" y="440"></use>
                                  <use xlink:href="#unit-v" class="segment e" x="0" y="250"></use>
                                  <use xlink:href="#unit-v" class="segment f" x="0" y="30"></use>
                                  <use xlink:href="#unit-h" class="segment g" x="30" y="220"></use>
                              </svg>
                          </div>
                          <div class="second">
                              <svg id="second-1" class="num-0" width="15.6" height="28.8" viewBox="0 0 260 480">
                                  <use xlink:href="#unit-h" class="segment a" x="30" y="0"></use>
                                  <use xlink:href="#unit-v" class="segment b" x="220" y="30"></use>
                                  <use xlink:href="#unit-v" class="segment c" x="220" y="250"></use>
                                  <use xlink:href="#unit-h" class="segment d" x="30" y="440"></use>
                                  <use xlink:href="#unit-v" class="segment e" x="0" y="250"></use>
                                  <use xlink:href="#unit-v" class="segment f" x="0" y="30"></use>
                                  <use xlink:href="#unit-h" class="segment g" x="30" y="220"></use>
                              </svg>
                              <svg id="second-2" class="num-0" width="15.6" height="28.8" viewBox="0 0 260 480">
                                  <use xlink:href="#unit-h" class="segment a" x="30" y="0"></use>
                                  <use xlink:href="#unit-v" class="segment b" x="220" y="30"></use>
                                  <use xlink:href="#unit-v" class="segment c" x="220" y="250"></use>
                                  <use xlink:href="#unit-h" class="segment d" x="30" y="440"></use>
                                  <use xlink:href="#unit-v" class="segment e" x="0" y="250"></use>
                                  <use xlink:href="#unit-v" class="segment f" x="0" y="30"></use>
                                  <use xlink:href="#unit-h" class="segment g" x="30" y="220"></use>
                              </svg>
                          </div>
                      </div>
                    </div>
                    <div class="timer-description ml-2" v-if="lesson.type !='audible_bonus' && lesson.type != 'mentalflash'">
                      El  CRONÓMETRO comenzará justo cuando toques el ábaco y parará cuando termines la última operación
                    </div>
                  </div>
                  <div class="expression-step">
                      <div class="expression" v-if="!finished">
                          <input type="hidden" id="tobeparsed" name="tobeparsed" value="243+4234">
                          <input type="hidden" id="isTutorial" name="isTutorial" value="1">

                          <!-- <input type="hidden" id="isDisable" name="isDisable" :value="lesson.type=='audible_bonus' || lesson.type == 'mentalflash'"> -->

                          <v-col class="equation" v-if="lesson.type=='practice'" lg="6" md="12">
                              <p class="equation-title">Ecuación</p>
                              <p class="equation-exp mt-4">{{equation}}</p>
                              <img :src="require('@/assets/images/equal.svg')" class="equation-equal">
                          </v-col>
                          <v-col class="equation" v-if="lesson.type=='mentalflash'" lg="6" md="12">
                              <div class="equation-acumula" id="flash-character">
                                <span>{{flashCurrentCharacter}}</span>
                              </div>
                              <img :src="require('@/assets/images/start.png')" class="equation-speaker" @click="startFlash()">
                              <img :src="require('@/assets/images/setting.png')" class="setting-speaker" @click="openDialog()">
                          </v-col>
                          <v-col class="equation" v-if="lesson.type=='audible_bonus'" lg="6" md="12">
                              <div class="equation-acumula" id="flash-character">
                                <span>Haga clic en el botón inicio</span>
                              </div>
                              <img :src="require('@/assets/images/speaker.png')" class="equation-speaker" @click="audioSpeaker()">
                              <img :src="require('@/assets/images/setting.png')" class="setting-speaker" @click="openDialog()">
                          </v-col>
                          <v-col class="response" lg="6" md="12">
                              <div class="res-body d-flex">
                                  <div class="abacus-result" v-if="lesson.type != 'audible_bonus' || lesson.type != 'mentalflash'">
                                    <p class="equation-title">Respuesta</p>
                                    <p id="abacus-result"></p>
                                  </div>
                                  <!--<div class="abacus-result af" v-if="lesson.type == 'audible_bonus' || lesson.type == 'mentalflash'">
                                    <p class="equation-title">Respuesta</p>
                                    <input id="abacus-result" class="equation-result" v-model="result" @change="changeResult" :disabled="flashIsStart && lesson.type == 'mentalflash'">
                                  </div> -->

                                  <v-btn class="text-capitalize response-btn playing" id="play2" @click="pageniation()" v-if="result == ''">Siguiente<span class="material-icons">arrow_forward_ios</span></v-btn>
                                  <v-btn class="text-capitalize response-btn" id="play2" @click="pageniation()" v-if="result != ''">Siguiente<span class="material-icons">arrow_forward_ios</span></v-btn>
                              </div>
                          </v-col>
                      </div>
                      <v-col class="expression-pageniation d-flex justify-content-ceneter" cols="12" v-bind:key="sel">
                          <div class="pagenation ml-2 mr-2"  v-for="(item, index) in status" v-bind:key="index" 
                          v-bind:class="[
                            item,
                            !isbattle ? '':
                            index < 5 ? 'veryeasy':
                            index < 10 ? 'easy':
                            index < 20 ? 'medium':
                            'hard'
                          ]">{{index+1}}</div>
                      </v-col>
                      <div class="abacus" v-if="!finished">
                          <div id="controls">
                            <p id="step" class="pull-right mt-4" v-if="isTutorial">Step 0/0</p>
                            <div class="alert alert-danger mt-4" id="parser_error" style="visibility: hidden"></div>
                            <div class="hidden tobeshown" v-if="lesson.type != 'audible_bonus' && lesson.type != 'mentalflash'">
                              <p id="comment" class="mt-4">Start!</p>
                            </div>
                            <div id="top_soroban">
                              
                              <canvas id="sorobanCanvas" height="218" width="720" @click="startTimer()">
                              </canvas>
                          
                              <table id="OpsAndCols" class="hidden tobeshown monospace">
                                  <tr id="cols"></tr>
                                  <tr id="ops"></tr>
                              </table>
                            </div>
                            
                          </div>
                          <div id="myimages" style="visibility:hidden">
                            <img src="/media/img/beads.png">
                            <img src="/media/img/digitbckg.png">
                          </div>
                      </div>
                  </div>

                  <div class="express-result text-center" v-if="finished">
                    <span>This is your points</span>
                    <p><b>{{points}}</b> / {{lesson.points}}</p>
                    <v-btn color="error" v-if="!passed" @click="reloadPage">Try Again!</v-btn>
                    <v-btn color="success" v-if="passed" @click="progress">OK</v-btn>
                  </div>
              </v-col>
              <v-col lg="2"></v-col>
          </v-row>

          <v-dialog
                v-model="dialog"
                max-width="400"
            >
            <v-card>
                <v-card-title class="text-h4">
                  <h2>
                    Barra de velocidad
                  </h2>
                </v-card-title>
                <v-card-text>
                  <input name="speed" type="range" min="1" max="7" step="1" v-model="rate" id="myRange">
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span>Da click aquí para comenzar</span>
                  <v-btn
                    color="green"
                    text
                    @click="audioSpeaker()"
                    v-if="lesson.type == 'audible_bonus'"
                  >
                  <img :src="require('@/assets/images/start.png')" height="50">
                  </v-btn>
                  <v-btn
                    color="green"
                    text
                    @click="startFlash()"
                    v-if="lesson.type == 'mentalflash'"
                  >
                  <img :src="require('@/assets/images/start.png')" height="50">
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-dialog>

          <v-dialog
                v-model="showCredit"
                max-width="300"
                class="pay-dialog"
          >
            <v-card  class="rounded-lg" :style="{ backgroundImage: 'url(' + require('@/assets/images/coin.gif') + ')' }">
              <v-card-text class="">
              </v-card-text>
              <v-card-actions class="pay-action">
                  <div class="paying-coin">
                    <img :src="require('@/assets/images/moneypic.svg')" width="80">
                    <div class="p-content">
                      <p class="title">
                        ¡Felicidades!
                      </p>
                      <p class="des">
                        Has ganado
                      </p>
                      <p class="coin">
                        {{points}} monedas
                      </p>
                    </div>
                  </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </div>      
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/begin/begin.scss";
.Zindex{
  z-index: 200;
}
.noZindex{
  z-index: 9;
}
</style>


<script>
import UserInfo from "@/view/content/user/Userinfo.vue";

import {
ADD_BODY_CLASSNAME,
REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import {
GET_QUESTION_DATA,
} from "@/core/services/store/data.module.js";

import {
POST_PROGRESS_DATA,
} from "@/core/services/store/progress.module.js";

import { mapGetters } from "vuex";

export default {
name: "inicio",
components: {
  UserInfo,
},
data() {
    return {
       sel:0,
       equation:{},
       begin:false,
       lesson: {},
       synth: null,
       questions: [],
       status: [],
       points: 0,
       recordTime: 0,
       recordTimer: null,
       isTutorial: true,
       finished: false,
       passed: false,
       rate: 3,
       defaultRate: 1,
       dialog: false,
       showCredit: false,
       result: '',
       flashIsStart: true,
       flashCurrentCharacter: 'Haga clic en el botón Inicio',
       isbattle: false,
       clicked: false,
       flashTimer: null,
       audioTimer: null,
    }
},
mounted() {
  
},
created() {
  if ('speechSynthesis' in window) {
    this.synth = window.speechSynthesis;
  }

  this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

  this.$store.dispatch(GET_QUESTION_DATA, this.parentId)
        .then(() => {
              this.lesson = this.questionData.meta;
              this.questions = [];

              this.questions = this.questionData.questions;

              this.initAbacus();

              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

              if(this.lesson.type == 'audible_bonus' || this.lesson.type == 'mentalflash') {
                this.dialog = true;
              }

        })
        .catch(() => {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });

},
beforeDestroy() {
  if(this.recordTimer != null) {
    clearInterval(this.recordTimer);
    this.recordTimer = null;
  }
  if (this.flashTimer != null) {
    clearInterval(this.flashTimer);
    this.flashTimer = null;
  }
},
methods: {
   startTimer() {
    if(this.recordTimer == null) {
      this.recordTimer = setInterval(function() {
      this.recordTime++;
      this.showingTime();
      }.bind(this), 1000);
    }
   },
   pageniation() {
    if (this.clicked) {
      return;
    }
    this.clicked = true;
    setTimeout(()=> {
      this.clicked = false;
    }, 1000);

      var result = document.getElementById('abacus-result').className;
      var currentStatus = document.getElementById('abacus-result').className;

      if(!result) {
          return;
      }

      // if(this.lesson.type == 'audible_bonus' || this.lesson.type == 'mentalflash') {
      //   if(this.result == '') {
      //     return;
      //   }

      //   if(eval(this.equation) == parseInt(this.result)) {
      //     currentStatus = 'success'
      //   } else {
      //     currentStatus = 'error'
      //   }
      //   this.result = '';
      // }


      if(currentStatus == 'success') {
        if(this.lesson.points >= 100) {
          this.points += 5;
        } else {
          this.points += 2.5;
        }
        if(this.points >= this.lesson.points * 0.8) {
          this.passed = true;              
        }
      }

      if(this.sel + 1 == this.lesson.count) {
        this.sel++;

        let num = this.sel;

        this.status[num - 1] = currentStatus;

        this.finished = true;
        
      } else {
        this.sel++;

        let num = this.sel;

        this.status[num - 1] = currentStatus;

        this.status[num] = 'open';

        this.equation = this.questions[num];
        document.getElementById('tobeparsed').value = this.equation;

        if(this.lesson.type == 'audible_bonus') {
          this.audioSpeaker();
        }

        if(this.lesson.type == 'mentalflash') {
          this.startFlash();
        }
      }

   },
   async audioSpeaker() {
      this.startTimer();
      this.dialog = false;
      this.equation = this.questions[this.sel];

      if (this.rate  == 1) {
        var equationVoice1 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', 'más').replaceAll('-', 'menos').split(' ');
        for (const i in equationVoice1) {
          this.readNumber(equationVoice1[i]);
          await this.timeout(4000);
        }
      } else if (this.rate == 2) {
        var equationVoice2 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', 'más').replaceAll('-', 'menos').split(' ');
        for (const i in equationVoice2) {
          this.readNumber(equationVoice2[i]);
          await this.timeout(3000);
        }
      } else if (this.rate == 3) {
        var equationVoice3 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', 'más').replaceAll('-', 'menos').split(' ');
        for (const i in equationVoice3) {
          this.readNumber(equationVoice3[i]);
        }
      } else if (this.rate == 4) {
        var equationVoice4 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', '\nmás').replaceAll('-', '\nmenos').split(' ');
        this.readNumber(equationVoice4)
      } else if (this.rate == 5) {
        var equationVoice5 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', ';más').replaceAll('-', ';menos').split(' ');
        this.readNumber(equationVoice5)
      } else if (this.rate == 6) {
        var equationVoice6 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', 'más').replaceAll('-', 'menos').split(' ');
        this.readNumber(equationVoice6)
      } else if (this.rate == 7) {
        var equationVoice7 = this.equation.replaceAll(/\./g, 'punto').replaceAll('+', 'más').replaceAll('-', 'menos').split(' ');
        this.defaultRate = 1.2;
        this.readNumber(equationVoice7)
      }
   },
   timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
   },
   readNumber(readingNumber) {
    const utterThis = new SpeechSynthesisUtterance(readingNumber);
      utterThis.lang = 'es-ES';
      utterThis.rate = this.defaultRate;
      this.synth.speak(utterThis);
   },
   startFlash() {
      this.startTimer();
      console.log('start flash ---------')
      if (this.flashTimer != null) {
        clearInterval(this.flashTimer);
      }
      this.dialog = false;
      this.equation = this.questions[this.sel];

      const question = this.equation;
      let flashCurrentQuestion = question.replace(/\+/g, '').replace(/\*/g, ' ').split(' ');
    
      let startIndex = 0;
      this.flashIsStart = true;
      this.flashCurrentCharacter = "Estar listo";

      setTimeout(() => {
        this.flashCurrentCharacter = "";
      }, this.rate * 1000 - 100);

      this.flashTimer = setInterval(() => {
        this.flashCurrentCharacter = flashCurrentQuestion[startIndex];
        if (startIndex === flashCurrentQuestion.length) {
          this.flashIsStart = false;
          clearInterval(this.flashTimer);
          this.flashCurrentCharacter = "Escribe tu respuesta";
        } else {
          setTimeout(() => {
            this.flashCurrentCharacter = "";
          }, this.rate * 1000 - 100);
        }

        startIndex++;

      }, this.rate * 1000);
   },
   initAbacus() {
      for(var i of this.questions) {
        i;
        this.status.push('pending')
      }

      this.equation = this.questions[0];
      this.status[0] = 'open';

      if(this.lesson.step > 1 || this.lesson.count > 20) {
        document.getElementById('isTutorial').value = 0;
        this.isTutorial = false;
      }

      if(this.lesson.count > 20) {
        this.isbattle = true;
      }

      document.getElementById('tobeparsed').value = this.equation;
      const plugin = document.createElement("script");
                      plugin.setAttribute(
                        "src",
                        "/js/abacus/all.minb6f5.js"
                      );
                      plugin.async = true;
                      document.body.appendChild(plugin);
   },
   reloadPage() {
     window.location.reload();
   },
   progress() {
    if(this.recordTimer != null) {
      clearInterval(this.recordTimer);
      this.recordTimer = null;
    }
    if(this.lesson.status == 'passed') {
      this.$router.go(-1);
      return;
    }

    if(this.lesson.type == 'audible_bonus') {
      this.points = 0;
      var params = {
        lesson_id: this.lesson._id,
        parent: this.lesson.parent,
        type: this.lesson.type,
        points: this.points,
        status: 'passed',
        step: this.lesson.step,
        time: this.recordTime,
        title: this.parentTitle + ' | ' + this.lesson.title
      };

    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    this.$store
      .dispatch(POST_PROGRESS_DATA, params)
      .then(() => {
          this.$router.go(-1);
      })
      .catch(() => {
          
      });
    } else {
      this.showCredit = true;
    }
   },
   openDialog() {
    this.dialog = true;
   },
   changeResult() {
    if(this.result == '') {
      return;
    }

   },
   showingTime() {
    let minute = new Date(this.recordTime * 1000).toISOString().slice(14, 16);
    let second = new Date(this.recordTime * 1000).toISOString().slice(17, 19);
    document.getElementById("minute-1").setAttribute("class","num-"+minute.substr(0,1));
    document.getElementById("minute-2").setAttribute("class","num-"+minute.substr(1,1));
    document.getElementById("second-1").setAttribute("class","num-"+second.substr(0,1));
    document.getElementById("second-2").setAttribute("class","num-"+second.substr(1,1));
  }
},
watch:{
  showCredit:function(newValue){
    if(!newValue){
      var params = {
        lesson_id: this.lesson._id,
        parent: this.lesson.parent,
        type: this.lesson.type,
        points: this.points,
        status: 'passed',
        level: this.level,
        step: this.lesson.step,
        time: this.recordTime,
        title: this.parentTitle + ' | ' + this.lesson.title
      };

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
      .dispatch(POST_PROGRESS_DATA, params)
      .then(() => {
          this.$router.go(-1);
      })
      .catch(() => {
          
      });
    }
  }
},
computed: {
    ...mapGetters([
          "questionData"
      ]),
      parentId: function() {
          return this.$route.params.id;
      },
      parentTitle: function () {
          return this.$route.params.parentTitle;
      },
      level: function() {
        return this.$route.params.level;
      }
},
}

</script>